export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 150
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  {
    title: '油罐号',
    key: 'tankNum'
  },
  {
    title: '油品',
    key: 'oilName'
  },
  {
    title: '余油高度(mm)',
    key: 'oilHeight'
  },
  {
    title: '余油体积(L)',
    key: 'finishVolume'
  },
  {
    title: '获取时间',
    key: 'saveTime',
    width: 180
  }
];
